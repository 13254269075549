













































import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  props: {
    left: Boolean,
  },
})
export default class Project extends Vue {
  @Prop({}) readonly title!: string;
  @Prop({}) readonly description!: string;
  @Prop({}) readonly icon!: string;
  @Prop({}) readonly blob!: string;
  @Prop({}) readonly color!: string;

  colorStyle = `color: ${this.color}`;

  // if desktop or not
  isDesktop() {
    return this.$vuetify.breakpoint.lgAndUp;
  }

  // open link
  open() {
    //window.open(this.link);
  }
}
