






























































































import { Component, Vue } from "vue-property-decorator";
import Project from "@/components/Project.vue";
import Portfolio from "@/components/Portfolio.vue";
import Navigation from "@/components/Navigation.vue";
import OpenProject from "@/components/OpenProject.vue";

@Component({
  components: { OpenProject, Navigation, Portfolio, Project },
})
export default class OpenSource extends Vue {
  projects = [
    {
      title: "restmap/restmap-node",
      description:
        "Simple way to query only the required data from a Rest API without relying on lot of external tools or code",
      icon: require("@/assets/restmap.svg"),
      color: "#2B7489",
      lang: "Typescript",
      link: "https://github.com/restmap/restmap-node",
    },

    {
      title: "json-keys-sort",
      description:
        "Sorting a json object based on keys either ascending or descending & even recursively",
      icon: null,
      color: "#F1E05A",
      lang: "Javascript",
      link: "https://www.npmjs.com/package/json-keys-sort",
    },

    {
      title: "wallup-android",
      description: "Unsplash unofficial client (v1 / branch v1)",
      icon: null,
      color: "#A97BFF",
      lang: "Kotlin",
      link: "https://github.com/dawnimpulse/wallup-android",
    },

    {
      title: "material-icons",
      description:
        "Custom web GUI for using material design icons, much faster & responsive as well as can be easily installed on local system",
      icon: null,
      color: "#2B7489",
      lang: "Typescript",
      link: "https://github.com/dawnimpulse/material-icons",
    },

    {
      title: "error-key",
      description:
        "Simplify your NodeJs project errors & give them unique numbers",
      icon: null,
      color: "#2B7489",
      lang: "Typescript",
      link: "https://github.com/dawnimpulse/error-key",
    },
    {
      title: "mediainfo",
      description:
        "A simple NodeJs server to return mediainfo from a video/audio url",
      icon: null,
      color: "#2B7489",
      lang: "Typescript",
      link: "https://github.com/dawnimpulse/mediainfo",
    },
    {
      title: "svg-and-patterns",
      description:
        "Great utilities for generating svg patterns & other tools for svg",
      icon: null,
      color: "#DEA057",
      lang: "Markdown",
      link: "https://github.com/dawnimpulse/svg-and-patterns",
    },
    {
      title: "calette-android",
      description: "Material colors & gradient palette android application",
      icon: null,
      color: "#A97BFF",
      lang: "Kotlin",
      link: "https://github.com/dawnimpulse/calette-android",
    },
    {
      title: "permissions-android",
      description: "Simplest permission handling library for Android",
      icon: null,
      color: "#A97BFF",
      lang: "Kotlin",
      link: "https://github.com/dawnimpulse/permissions-android",
    },
  ];

  // if desktop or not
  isDesktop() {
    return this.$vuetify.breakpoint.lgAndUp;
  }

  // open link
  open() {
    //window.open(this.link);
  }
}
