






















































import { Component, Vue } from "vue-property-decorator";

@Component({
  props: {
    icon: String,
    title: String,
    description: String,
    color: String,
    lang: String,
    link: String,
  },
})
export default class OpenProject extends Vue {
  // if desktop or not
  isDesktop() {
    return this.$vuetify.breakpoint.lgAndUp;
  }

  // open link in new tab
  open(link: string) {
    window.open(link);
  }
}
